.showcase {
  // background-image: url("../../assets/img/bg.png");
  background-image: linear-gradient(rgba(0, 0, 0, 0.767), rgba(0, 0, 0, 0.767)),
    url("../../assets/img/bg.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .cus-nav {
    background-color: transparent;
    color: #fff;
    .si {
      height: 40px;
    }

    .link-style {
      color: #fff !important;
      margin-left: 1rem;
      font-weight: bold;
    }
    .logo {
      height: 80px;
    }
  }

  .showcase-content {
    height: 84vh;

    .showcase-title {
      font-size: 5rem;
      font-weight: bold;
      color: #3ad8b2;
      text-align: center;
    }

    .tit {
      color: white;
      font-weight: bold;
    }
    .showlogo {
      height: 50px;
    }
  }

  .linkh {
    color: #666;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
  }
  .linkh:after {
    display: block;
    content: "";
    border-bottom: solid 3px #fff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .linkh:hover:after {
    transform: scaleX(1);
  }
  .linkh.fromRight:after {
    transform-origin: 100% 50%;
  }
  .linkh.fromLeft:after {
    transform-origin: 0% 50%;
  }
}

.expicon {
  height: 30px;
}

// .road {
//   height: 400px;
// }

.gsec {
  margin-top: 13rem;
}

.text-pri {
  color: #fff !important;
}

.title {
  font-weight: bold;
  font-size: 2.6rem;
}

.subtitle {
  font-size: 1rem;
  line-height: 28px;
  font-weight: 900;
  letter-spacing: 0.5px;
}

.about {
  margin-top: 10rem;
}

.slider {
  margin-top: 10rem;
  .slider-div {
    margin: 0 !important;
    padding: 0 !important;
    .slider-img {
      height: 285px;
    }
  }
}

.dracoexp {
  margin-top: 10rem;

  .con-1 {
    margin-top: 5rem;
  }
  .exp-title {
    font-size: 1.1rem;
    font-weight: 900;
  }
  .exp-subtitle {
    font-weight: 900;
    line-height: 22px;
    font-size: 0.9rem;
  }

  .p-title {
    font-size: 2.25rem;
    color: #e7e8d1;
    font-weight: bold;
  }
  .p-subtitle {
    font-size: 1rem;
    font-weight: 900;
  }
}

.content-title {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap {
  margin-top: 10rem;
}

.team {
  margin-top: 10rem;

  .team-img {
    height: 400px;
    border-radius: 2rem;
    border: 2px solid #fff;
  }
  .social-icon {
    height: 35px;
  }
}

.faq {
  margin-top: 5rem;

  .con-bot {
    font-weight: 500;
    color: #e7e8d1;
    font-size: 1.6rem !important;
  }
}

.footer {
  background-image: url("../../assets/img/Group.png");

  .footer-text {
    font-weight: bold;
  }
}

.flogo {
  height: 150px;
}

.mobile {
  display: none !important;
}

// New css
.about-img {
  border-radius: 1rem;
}

.mint-page {
  margin-bottom: 10rem;
}

.cusbtn {
  background-color: #2a2d36;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  letter-spacing: 3px;
}

.cusbtn:hover {
  color: #fff;
}

.mintbutton {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  letter-spacing: 3px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.mint {
  background-color: #161f23a6;
  padding: 2rem 3rem;
  border-radius: 1.5rem;
  border: 2px solid #fe231d;
}

.mih1 {
  color: #fe231d;
  font-weight: bold;
  font-size: 2rem;
  letter-spacing: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.plus-img {
  height: 60px;
  cursor: pointer;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}

.display-number {
  color: #fff;
  height: 70px;
  width: 70px;
}
.number {
  font-weight: bold;
  font-size: 3rem;
}

.si {
  height: 50px;
  margin: 0 10px;
}

@media only screen and (max-width: 600px) {
  .slider-img {
    height: 60.5px;
  }

  .rmt {
    margin-top: 5rem;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block !important;
  }

  .countd {
    align-items: center !important;
  }

  .showcase-title {
    font-size: 1rem !important;
  }

  .showcase-subtitle {
    font-size: 1rem !important;
  }

  .showcase {
    height: auto !important;
    .showcase-content {
      height: auto !important;
      margin-top: 2rem !important;

      .mbcon {
        margin-top: 2rem !important;
      }
    }
  }
}
